const defaultStyle = 'size-smallest rounded-infinite bg-[currentColor]'

const Loading = () => (
  <div className="flex flex-row gap-smallest">
    <span className={`${defaultStyle} animate-[loading_1s_infinite_100ms]`} />
    <span className={`${defaultStyle} animate-[loading_1s_infinite_300ms]`} />
    <span className={`${defaultStyle} animate-[loading_1s_infinite_500ms]`} />
  </div>
)

Loading.displayName = 'Loading'

export default Loading
